var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"products-list-page",attrs:{"fluid":""}},[_c('FeedbackCofenReportHeader',{attrs:{"loading":_vm.loading},on:{"change-date":function($event){return _vm.changeDate($event)},"find-clicked":function($event){return _vm.find($event)}}}),(_vm.reports)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"elevation-3 rounded",staticStyle:{"background-color":"white","width":"100%","padding":"12px"}},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"20px"}},[_vm._v(" All Report ("+_vm._s(_vm.reports.survey.total)+") ")]),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.allHeaders,"items":_vm.reports.all,"item-per-page":-1,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.score",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},_vm._l((item.score),function(i){return _c('v-icon',{key:i,attrs:{"color":"#fcda19"}},[_vm._v(" mdi-star-face ")])}),1)]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.total) + " (" + (item.count) + ")"))+" ")]}},{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPercent(item.count, (_vm.reports.bot.count + _vm.reports.admin.count)))+"% ")]}}],null,true)})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"elevation-3 rounded",staticStyle:{"background-color":"white","width":"100%","padding":"12px"}},[_c('div',{staticClass:"content-header"},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"20px"}},[_vm._v(" Bots Report ("+_vm._s(_vm.reports.survey.bot)+") ")]),_c('v-btn',{attrs:{"icon":"","fab":"","color":"secondary","small":"","disabled":!_vm.reports.bot.recommended.length},on:{"click":function($event){return _vm.showRecommended(_vm.reports.bot.recommended)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.allHeaders,"items":_vm.reports.bot.scores,"item-per-page":-1,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.score",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},_vm._l((item.score),function(i){return _c('v-icon',{key:i,attrs:{"color":"#fcda19"}},[_vm._v(" mdi-star-face ")])}),1)]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.total) + " (" + (item.count) + ")"))+" ")]}},{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPercent(item.count, _vm.reports.bot.count))+"% ")]}}],null,true)})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"elevation-3 rounded",staticStyle:{"background-color":"white","width":"100%","padding":"12px"}},[_c('div',{staticClass:"content-header"},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"20px"}},[_vm._v(" Admins Report ("+_vm._s(_vm.reports.survey.admin)+") ")]),_c('v-btn',{attrs:{"icon":"","fab":"","color":"secondary","small":"","disabled":!_vm.reports.admin.recommended.length},on:{"click":function($event){return _vm.showRecommended(_vm.reports.admin.recommended)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('v-data-table',{staticClass:"mt-3",attrs:{"id":"feedback-admin-table","headers":_vm.adminHeaders,"items":_vm.reports.admin.scores,"item-per-page":-1,"loading":_vm.loading,"show-expand":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.total) + " (" + (item.count) + ")"))+" ")]}},{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPercent(item.count, _vm.reports.admin.count))+"% ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pr-0 pl-6",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.allHeaders,"items":item.scores,"item-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.score",fn:function(ref){
var scoreItem = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},_vm._l((scoreItem.score),function(i){return _c('v-icon',{key:i,attrs:{"color":"#fcda19"}},[_vm._v(" mdi-star-face ")])}),1)]}},{key:"item.total",fn:function(ref){
var scoreItem = ref.item;
return [_vm._v(" "+_vm._s(((scoreItem.total) + " (" + (scoreItem.count) + ")"))+" ")]}},{key:"item.percent",fn:function(ref){
var scoreItem = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPercent(scoreItem.count, item.count))+"% ")]}}],null,true)})],1)]}}],null,true)})],1)])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.recommendedDialog),callback:function ($$v) {_vm.recommendedDialog=$$v},expression:"recommendedDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Recommended List ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.recommendedHeaders,"items":_vm.recommended,"items-per-page":-1,"hide-default-footer":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }