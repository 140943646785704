<template>
  <v-container
    class="products-list-page"
    fluid>
    <FeedbackCofenReportHeader
      :loading="loading"
      @change-date="changeDate($event)"
      @find-clicked="find($event)" />
    <v-row v-if="reports">
      <v-col cols="12">
        <div
          class="elevation-3 rounded"
          style="
            background-color: white;
            width: 100%;
            padding: 12px;
          ">
          <span style="font-weight: bold; font-size: 20px;">
            All Report ({{ reports.survey.total }})
          </span>
          <v-data-table
            :headers="allHeaders"
            :items="reports.all"
            :item-per-page="-1"
            :loading="loading"
            hide-default-footer
            class="mt-3">
            <template #[`item.score`]="{ item }">
              <div class="d-flex flex-row">
                <v-icon
                  v-for="i in item.score"
                  :key="i"
                  color="#fcda19">
                  mdi-star-face
                </v-icon>
              </div>
            </template>
            <template #[`item.total`]="{ item }">
              {{ `${item.total} (${item.count})` }}
            </template>
            <template #[`item.percent`]="{ item }">
              {{ getPercent(item.count, (reports.bot.count + reports.admin.count)) }}%
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="12">
        <div
          class="elevation-3 rounded"
          style="
            background-color: white;
            width: 100%;
            padding: 12px;
          ">
          <div class="content-header">
            <span style="font-weight: bold; font-size: 20px;">
              Bots Report ({{ reports.survey.bot }})
            </span>
            <v-btn
              icon
              fab
              color="secondary"
              small
              :disabled="!reports.bot.recommended.length"
              @click="showRecommended(reports.bot.recommended)">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>

          <v-data-table
            :headers="allHeaders"
            :items="reports.bot.scores"
            :item-per-page="-1"
            :loading="loading"
            hide-default-footer
            class="mt-3">
            <template #[`item.score`]="{ item }">
              <div class="d-flex flex-row">
                <v-icon
                  v-for="i in item.score"
                  :key="i"
                  color="#fcda19">
                  mdi-star-face
                </v-icon>
              </div>
            </template>
            <template #[`item.total`]="{ item }">
              {{ `${item.total} (${item.count})` }}
            </template>
            <template #[`item.percent`]="{ item }">
              {{ getPercent(item.count, reports.bot.count) }}%
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="12">
        <div
          class="elevation-3 rounded"
          style="
            background-color: white;
            width: 100%;
            padding: 12px;
          ">
          <div class="content-header">
            <span style="font-weight: bold; font-size: 20px;">
              Admins Report ({{ reports.survey.admin }})
            </span>
            <v-btn
              icon
              fab
              color="secondary"
              small
              :disabled="!reports.admin.recommended.length"
              @click="showRecommended(reports.admin.recommended)">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>

          <v-data-table
            id="feedback-admin-table"
            :headers="adminHeaders"
            :items="reports.admin.scores"
            :item-per-page="-1"
            :loading="loading"
            show-expand
            hide-default-footer
            class="mt-3">
            <template #[`item.total`]="{ item }">
              {{ `${item.total} (${item.count})` }}
            </template>
            <template #[`item.percent`]="{ item }">
              {{ getPercent(item.count, reports.admin.count) }}%
            </template>
            <template #expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="pr-0 pl-6">
                <v-data-table
                  :headers="allHeaders"
                  :items="item.scores"
                  :item-per-page="-1"
                  hide-default-footer>
                  <template #[`item.score`]="{ item: scoreItem }">
                    <div class="d-flex flex-row">
                      <v-icon
                        v-for="i in scoreItem.score"
                        :key="i"
                        color="#fcda19">
                        mdi-star-face
                      </v-icon>
                    </div>
                  </template>
                  <template #[`item.total`]="{ item: scoreItem }">
                    {{ `${scoreItem.total} (${scoreItem.count})` }}
                  </template>
                  <template #[`item.percent`]="{ item: scoreItem }">
                    {{ getPercent(scoreItem.count, item.count) }}%
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="recommendedDialog"
      max-width="500px">
      <v-card>
        <v-card-title>
          Recommended List
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="recommendedHeaders"
            :items="recommended"
            :items-per-page="-1"
            hide-default-footer
            class="elevation-3">
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import ChatProvider from '@/resources/ChatProvider'
import FeedbackCofenReportHeader from '../components/FeedbackCofenReportHeader.vue'

const ChatService = new ChatProvider()

export default {
  components: {
    FeedbackCofenReportHeader
  },
  data () {
    return {
      reports: null,
      dates: [null, null],
      loading: false,
      allHeaders: [
        {
          text: 'Score',
          value: 'score',
          align: 'start'
        },
        {
          text: 'Total',
          value: 'total',
          align: 'center'
        },
        {
          text: 'Percent',
          value: 'percent',
          align: 'center'
        }
      ],
      adminHeaders: [
        {
          text: 'Name',
          value: 'name',
          align: 'start'
        },
        {
          text: 'Total',
          value: 'total',
          align: 'center'
        },
        {
          text: 'Percent',
          value: 'percent',
          align: 'center'
        },
        {
          text: '',
          value: 'data-table-expand'
        }
      ],
      recommendedHeaders: [
        {
          text: 'Score',
          value: 'score',
          align: 'center'
        },
        {
          text: 'Message',
          value: 'text',
          align: 'start'
        }
      ],
      recommended: [],
      recommendedDialog: false
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    changeDate (date) {
      this.dates = date
    },
    find (payload) {
      this.fetchData(payload)
    },
    async fetchData (payload) {
      try {
        this.loading = true

        const { data } = await ChatService.getChatFeedbackReport(payload)
        this.reports = {
          ...data,
          all: [
            ...data.all,
            {
              score: '',
              total: data.allTotal,
              count: data.all.reduce((sum, a) => sum + a.count, 0)
            }
          ],
          bot: {
            ...data.bot,
            scores: [
              ...data.bot.scores,
              {
                score: '',
                total: data.botTotal,
                count: data.bot.scores.reduce((sum, a) => sum + a.count, 0)
              }
            ]
          }
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    showRecommended (items) {
      if (items.length) {
        this.recommended = [...items]
        this.recommendedDialog = true
      }
    },
    getPercent (a, b) {
      if (a <= 0 || b <= 0) {
        return (0).toFixed(2)
      }

      return ((a / b) * 100).toFixed(2)
    }
  }
}
</script>

<style scoped>
.content-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>

<style>
.stats-card {
  min-width: 15%;
  margin: 8px;
}
#feedback-admin-table .v-data-table__expanded__content {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
</style>
