<template>
  <v-row
    justify="space-between"
    class="head-search ma-0 rounded">
    <v-col
      cols="4"
      class="d-flex align-center date-picker-col">
      <date-range-picker
        v-model="queryDates"
        @change="dateChange($event)" />
    </v-col>
    <v-col cols="auto">
      <v-select
        v-model="brandSelected"
        label="เลือก Brand"
        :items="allBrand"
        dense
        solo
        class="mr-2"
        item-text="text"
        item-value="value"
        hide-details />
    </v-col>
    <v-spacer />
    <v-col
      cols="4"
      class="d-flex align-center date-picker-col">
      <v-spacer />
      <v-btn
        class="mx-2"
        color="secondary"
        :loading="loading"
        @click="find()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import DateRangePicker from '@/components/DateRangePicker.vue'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'

const ProductAttributeService = new ProductAttributeProvider()
export default {
  components: {
    DateRangePicker
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      queryDates: [this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
      brandSelected: 'all',
      allBrand: []
    }
  },
  watch: {
    queryDates: {
      handler (value) {
        this.$emit('change-date', value)
      },
      deep: true
    }
  },
  async mounted () {
    await this.getBrand()
  },
  methods: {
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.allBrand = [
        {
          text: 'All',
          value: 'all'
        },
          ...data.results.map((brand) => ({
            text: brand.name,
            value: brand.name
          }))
        ]
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    dateChange (date) {
      this.$emit('change-date', date)
    },
    find () {
      const payload = {
        startDate: this.$dayjs(this.queryDates[0]).utc().toISOString(),
        endDate: this.$dayjs(this.queryDates[1]).add(1, 'day').utc().toISOString(),
        brand: this.brandSelected
      }
      this.$emit('find-clicked', payload)
    }
  }
}
</script>
